import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import AppBar from './AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {selectors as accountSelectors} from '../../Ducks/account';
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import {selectors as adminSelectors} from "../../Ducks/admin";

const menuList = [
    {
        title: "Dashboard",
        to: '/',
        capabilities: ['admin', 'salesrep']
    },
    {
        title: "Manage",
        capabilities: ['admin', 'salesrep'],
        items: [
            {
                title: "Companies",
                to: '/companies',
                capabilities: ['admin']
            },
            {
                title: 'Parent Companies',
                to: '/manage-companies/companies',
                capabilities: ['admin']
            },
            {
                title: 'Bounced Email',
                to: '/bounced-emails',
                capabilities: ['admin']
            },
            {
                title: 'Manage Bounced Email',
                to: '/bounced-emails/attachments',
                capabilities: ['admin']
            },
            {
                title: 'Todos',
                to: '/todos',
                capabilities: ['admin']
            },
            {
                title: 'Assign Todos To KPIs',
                to: '/assign-todos-to-kpis',
                capabilities: ['admin']
            },
            {
                title: 'Settings',
                to: '/site-settings',
                capabilities: ['can_manage_settings']
            }
        ]
    },
    {
        title: "Products",
        to: '/products',
        capabilities: ['admin']
    },
    {
        title: "Sales",
        to: '/sales',
        capabilities: ['admin', 'salesrep'],
        items: [
            {
                title: 'Pipeline',
                to: '/sales/pipeline',
                capabilities: ['admin', 'salesrep']
            },
            {
                title: 'Calendar',
                to: '/sales/calendar',
                capabilities: ['admin', 'salesrep']
            },
            {
                title: "Opportunities",
                to: '/opportunities',
                capabilities: ['admin']
            },
            {
                title: "Finance",
                to: "/finance",
                capabilities: ['admin', 'salesrep']
            }
        ]
    },
    {
        title: "Admin",
        capabilities: ['admin'],
        items: [
            {
                title: 'Capabilities',
                to: '/admin/capabilities'
            },
            {
                title: 'Manage Users',
                to: '/admin/users'
            },
            {
                title: 'Manage Teams',
                to: '/admin/teams'
            },
            {
                title: 'Mail Templates',
                to: '/admin/mail-templates/create'
            },
            {
                title: 'Segment Builder',
                to: '/admin/segment-builder',
                capabilities: ['segment_builder_restricted']
            },
            {
                title: 'Manage G-Data',
                to: '/email-extraction-data',
                capabilities: ['admin']
            },
            {
                title: 'Release Notes',
                to: 'admin/release-notes',
                capabilities: ['can_manage_release_notes']
            },
        ]
    },
    {
        title: "Stats",
        capabilities: ['admin', 'event_overview'],
        items: [
            {
                title: 'Case Overview',
                to: '/case/overview',
                capabilities: ['admin', 'case_overview', 'ongoing_case_reviewer', 'pause_case_reviewer']
            },
            {
                title: 'Resale Overview',
                to: '/resale/overview',
                capabilities: ['admin', 'resale_overview']
            },
            {
                title: 'Events Overview',
                to: '/events/overview',
                capabilities: ['admin', 'event_overview']
            },
        ]
    },
    {
        title: "Contacts",
        to: '/contacts',
        capabilities: ['admin'],
        items: [
            {
                title: "Change Member Group",
                to: '/change-member-group',
                capabilities: ['admin'],
            },
            {
                title: "Transfer Membership",
                to: '/transfer-membership',
                capabilities: ['admin'],
            }
        ]
    },
];

const salesRepMenuList = [
    {
        title: "Dashboard",
        to: '/'
    },
    {
        title: "Pipeline",
        to: '/sales/pipeline'
    },
    {
        title: "Calendar",
        to: '/sales-view/calendar'
    },
    {
        title: "Finance",
        to: "/finance",
        capabilities: ['admin', 'salesrep']
    },
    {
        title: 'Todos',
        to: '/todos',
        capabilities: ['admin', 'salesrep']
    },
];

function Layout(props) {
    const {user, siteSettings} = props;
    const [open, setOpen] = React.useState(false);
    const role = user && user.user_details.capabilities.filter((element) => element.capability_title === "admin" || element.capability_title === "salesrep");
    const [displayMenuList, setDisplayMeniList] = useState(menuList);
    const handleDrawer = () => {
        setOpen(!open);
    };

    const checkAdminRole = () => {
        return role.filter((item) => item.capability_title === "admin").length
    }

    const checkSalesRepRole = () => {
        return role.filter((item) => item.capability_title === "salesrep").length
    }

    const isModuleEnabled = (menuItem) => {
        let enabled = true;

        siteSettings.forEach((setting) => {
            const settingMetaValue = JSON.parse(setting.meta_value);

            if (setting.meta_name === menuItem && settingMetaValue.module_status == 0) {
                enabled = false;
            }

            let subModules = settingMetaValue.menu_items;

            subModules.forEach((subModule) => {

                Object.keys(subModule).forEach(subModuleItem => {
                    if (subModuleItem === menuItem) {
                        if (settingMetaValue.module_status == 0) {
                            enabled = false;
                        } else if (subModule[subModuleItem].show_in_menu == 0) {
                            enabled = false;
                        }
                    }
                });
            })
        })

        return enabled;
    }

    useEffect(() => {
        if (checkAdminRole()) {
            menuList?.forEach((item, index) => {
                let moduleEnabled = isModuleEnabled(item.title);

                if (item.hasOwnProperty('items')) {
                    item.items.forEach((childItem, childIndex) => {
                        const subMenuEnabled = isModuleEnabled(childItem.title);

                        if (subMenuEnabled === false) {
                            //module is inactive, so remove from list.
                            item.items.splice(childIndex, 1);
                        }
                    });
                } else {
                    if (!moduleEnabled) {
                        //module is inactive, so remove from list.
                        menuList.splice(index, 1);
                    }
                }
            })

            setDisplayMeniList((menuList));
        } else if (checkSalesRepRole()) {
            salesRepMenuList?.forEach((item, index) => {
                let moduleEnabled = isModuleEnabled(item.title);

                if (item.hasOwnProperty('items')) {
                    item.items.forEach((childItem, childIndex) => {
                        const subMenuEnabled = isModuleEnabled(childItem.title);

                        if (subMenuEnabled === false) {
                            //module is inactive, so remove from list.
                            item.items.splice(childIndex, 1);
                        }
                    });
                } else {
                    if (!moduleEnabled) {
                        //module is inactive, so remove from list.
                        menuList.splice(index, 1);
                    }
                }
            })
            setDisplayMeniList((menuList));
        } else {
            menuList?.forEach((item, index) => {
                let moduleEnabled = isModuleEnabled(item.title);

                if (item.hasOwnProperty('items')) {
                    item.items.forEach((childItem, childIndex) => {
                        const subMenuEnabled = isModuleEnabled(childItem.title);

                        if (subMenuEnabled === false) {
                            //module is inactive, so remove from list.
                            item.items.splice(childIndex, 1);
                        }
                    });
                } else {
                    if (!moduleEnabled) {
                        //module is inactive, so remove from list.
                        menuList.splice(index, 1);
                    }
                }
            })
            setDisplayMeniList((menuList));
        }
    }, [siteSettings]);

    return (
        <Box sx={{width: '100%'}}>
            <CssBaseline/>
            <AppBar handleDrawer={handleDrawer}
                    menuList={displayMenuList}/>
            <ErrorBoundary>
                <Box
                    component="main"
                    sx={
                        {
                            flexGrow: 1,
                            p: 3,
                            marginLeft: 0,
                            backgroundColor: '#F7F7F7'
                        }
                    }>
                    {props.children}
                </Box>
            </ErrorBoundary>
        </Box>
    );
}

const hasChildren = (item) => {
    const {items: children} = item;

    if (children === undefined) {
        return false;
    }

    if (children.constructor !== Array) {
        return false;
    }

    if (children.length === 0) {
        return false;
    }

    return true;
}


const MenuItem = ({item, space}) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} space={space}/>;
};

const SingleLevel = ({item}) => {
    return (
        <Link style={{textDecoration: 'none', color: 'black'}} to={item.to}>
            <ListItem button>
                {item.title}
            </ListItem>
        </Link>
    );
};

const MultiLevel = ({item}) => {
    const {items: children} = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <div>
            <ListItem button onClick={handleClick}>
                <ListItemText primary={item.title}/>
                {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit style={{backgroundColor: '#f0f0f0'}}>
                <List component="div" disablePadding>
                    {children.map((child, key) => (
                        <MenuItem key={key} item={child} space={true}/>
                    ))}
                </List>
            </Collapse>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: accountSelectors.user(state),
    siteSettings: adminSelectors.siteSettings(state),
});

export default connect(mapStateToProps, {})(Layout);