import React, {useEffect, useMemo} from "react";
import {
    useTable,
    usePagination,
    useFilters,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    useExpanded
} from "react-table";
// A great library for fuzzy filtering/sorting items
import {matchSorter} from "match-sorter";
import {Pagination} from "@mui/material";
import {ArrowDropUp, ArrowDropDown} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import {debounce} from "lodash";

// Define a default UI for filtering
function DefaultColumnFilter({column: {filterValue, setFilter}}) {
    return (
        <input
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search`}
            style={{width: "100%"}}
        />
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, {keys: [(row) => row.values[id]]});
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Define a custom filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number

filterGreaterThan.autoRemove = (val) => typeof val !== "number";


const getCellColor = (data, column) => {
    if (column === "invoices.invoice_status" && data.invoice_status) {
        if (data.invoice_status === "Pending") {
            var currentDate = new Date();
            var deadline = new Date(data.invoice_deadline_date);

            var yellowDate = new Date(data.invoice_deadline_date);
            yellowDate.setDate(yellowDate.getDate() - 4);

            var orangeDate = new Date(data.invoice_deadline_date);
            orangeDate.setDate(orangeDate.getDate() - 2);

            if (currentDate >= deadline) {
                return "red";
            } else if (currentDate >= orangeDate) {
                return "orange";
            } else if (currentDate >= yellowDate) {
                return "yellow";
            }
        } else {
            return "green";
        }
    }
};

const getCellTextColor = (data, column) => {
    if (column === "invoices.invoice_status" && data.invoice_status) {
        if (data.invoice_status === "Pending") {
            var currentDate = new Date();
            var deadline = new Date(data.invoice_deadline_date);

            var yellowDate = new Date(data.invoice_deadline_date);
            yellowDate.setDate(yellowDate.getDate() - 4);

            var orangeDate = new Date(data.invoice_deadline_date);
            orangeDate.setDate(orangeDate.getDate() - 2);

            if (currentDate >= deadline) {
                return "white"; //if background is red
            } else if (currentDate >= orangeDate) {
                return "black";
            } else if (currentDate >= yellowDate) {
                return "black";
            }
        } else {
            return "white"; //if background is green
        }
    }
};

// Table component
const GridElement = ({
                         columns,
                         data,
                         pageCount: controlledPageCount,
                         loading,
                         fetchData,
                         filters,
                         setFilters,
                         controlledPageIndex,
                         setControlledPageIndex,
                         rowsCount,
                         handleRowClick,
                         initialSortBy,
                         sortOrder,
                         hideSelection,
                         refreshGridData,
                         selectableCount,
                         handleRowSelection
                     }) => {
    const [allRowsSelected, setAllRowsSelected] = React.useState(false);
    const [isRowsSelected, setIsRowsSelected] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState({});

    const toggleSelectAllRows = (e) => {
        const checked = e.target.checked;
        setAllRowsSelected(checked);
        if (checked) {
            setSelectedRows({
                ...selectedRows,
                [controlledPageIndex]: data.map((r) => r[columns[0].accessor]),
            });
        } else if (selectedRows[controlledPageIndex]) {
            setSelectedRows({...selectedRows, [controlledPageIndex]: []});
            setIsRowsSelected(false);
        }
    };

    useEffect(() => {
        const _selectedRows = selectedRows[controlledPageIndex] || [];

        setAllRowsSelected(data.length === _selectedRows.length);
        setIsRowsSelected(_selectedRows.length > 0);
    }, [data]);

    const toggleRowSelect = (id) => {
        let _selectedRows = selectedRows[controlledPageIndex]
            ? [...selectedRows[controlledPageIndex]]
            : [];

        let selectedCount = _selectedRows.length;
        let checked = false;

        if (_selectedRows.includes(id)) {
            _selectedRows = _selectedRows.filter((rId) => rId !== id);
            checked = false;
        } else {
            if (selectableCount === 1) {
                //uncheck previous selection and select new row.
                _selectedRows = [id];
            } else {
                if (selectedCount < selectableCount) {
                    _selectedRows.push(id);
                }
            }

            checked = true;
        }

        setAllRowsSelected(_selectedRows.length === data.length);
        setSelectedRows({
            ...selectedRows,
            [controlledPageIndex]: _selectedRows,
        });
        setIsRowsSelected(_selectedRows.length > 0);
        handleRowSelection(checked, id);
    };

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        pageCount,
        gotoPage,
        setPageSize,
        // Get the state from the instance
        state: {pageIndex, pageSize, sortBy},
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: {
                pageIndex: controlledPageIndex,
                pageSize: 50,
                filters,
                sortBy: [
                    {
                        id: initialSortBy,
                        desc: sortOrder == "desc" ? true : false,
                    },
                ],
            }, // if you don't set that pageIndex a dynamic value, it will re-render to its default value 0.
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            pageCount: controlledPageCount,
            manualFilters: true,
            manualSortBy: true,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    );

    // Remove the checkbox column from rendering
    const hiddenColumns = ["_selector"];
    hiddenColumns.forEach((columnId) => {
        const index = columns.findIndex((column) => column.id === columnId);
        if (index !== -1) {
            columns.splice(index, 1);
        }
    });

    const debounceFetchData = useMemo(() => debounce(fetchData, 1100), []);

    // Listen for changes in pagination and use the state to fetch our new data
    React.useEffect(() => {
        fetchData &&
        debounceFetchData({
            pageIndex,
            pageSize,
            sortBy,
            filters: state.filters,
            columns,
        });

        const timer = setTimeout(() => {
            if (state.filters.length) {
                setFilters(state.filters);
            }
            setControlledPageIndex(pageIndex);
        }, 300); // 300ms debounce delay

        // Cleanup the timeout on value change or component unmount
        return () => clearTimeout(timer);
    }, [
        fetchData,
        pageIndex,
        pageSize,
        sortBy,
        setFilters,
        state.filters,
        columns,
    ]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            fetchData &&
            fetchData({
                pageIndex,
                pageSize,
                sortBy,
                filters: filters,
                columns,
            });
        }, 300); // 300ms debounce delay

        // Cleanup the timeout on value change or component unmount
        return () => clearTimeout(timer);
    }, [filters]);

    React.useEffect(() => {
        fetchData &&
        fetchData({
            pageIndex,
            pageSize,
            sortBy,
            filters: filters,
            columns,
        });
    }, [refreshGridData]);

    return (
        <div className="react-table-container">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                }}
            >
                <div>
                    <span>Total: {rowsCount}</span>
                </div>
                <div className="pagination">
                    <Pagination
                        color="primary"
                        count={pageCount}
                        page={pageIndex + 1}
                        onChange={(_event, value) => {
                            gotoPage(value - 1);
                        }}
                    />
                    <span>
                        | Go to page:{" "}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value
                                    ? Number(e.target.value) - 1
                                    : 0;
                                gotoPage(page);
                            }}
                            style={{width: "100px"}}
                        />
                    </span>{" "}
                    <select
                        style={{minHeight: "25px", marginLeft: "5px"}}
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="react-table-wrapper">
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            style={{background: "#f3f3f3"}}
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {!hideSelection && (
                                <th
                                    style={{
                                        minWidth: "0",
                                        paddingLeft: "1rem",
                                        paddingRight: "1rem",
                                    }}
                                >
                                    {selectableCount > 1 && <Checkbox
                                        indeterminate={
                                            !allRowsSelected &&
                                            isRowsSelected
                                        }
                                        checked={allRowsSelected}
                                        onClick={toggleSelectAllRows}
                                    />}
                                </th>
                            )}
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    <div>
                                        {column.render("Header")}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <ArrowDropDown
                                                            sx={{
                                                                marginLeft:
                                                                    "5px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <ArrowDropUp
                                                            sx={{
                                                                marginLeft:
                                                                    "5px",
                                                            }}
                                                        />
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                            </span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {!hideSelection && (
                                <th style={{minWidth: "0"}}></th>
                            )}
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {/* Render the columns filter UI */}
                                    <div>
                                        {column.canFilter
                                            ? column.render("Filter")
                                            : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    {loading ? (
                        <tbody {...getTableBodyProps()}>
                        <tr>
                            <td colSpan="10000">Loading...</td>
                        </tr>
                        </tbody>
                    ) : (
                        <tbody {...getTableBodyProps()}>
                        {!page.length ? (
                            <tr>
                                <td
                                    colSpan={100}
                                    style={{textAlign: "center"}}
                                >
                                    No record found{" "}
                                </td>
                            </tr>
                        ) : (
                            page.map((row) => {
                                prepareRow(row);
                                return (
                                    <React.Fragment key={row.id}>
                                        <tr {...row.getRowProps()}>
                                            {!hideSelection && (
                                                <td style={{minWidth: "0"}}>
                                                    <Checkbox
                                                        checked={
                                                            allRowsSelected ||
                                                            !!selectedRows[
                                                                controlledPageIndex
                                                                ]?.includes(
                                                                row.allCells[0]
                                                                    .value
                                                            )
                                                        }
                                                        onClick={() =>
                                                            toggleRowSelect(
                                                                row.allCells[0]
                                                                    .value
                                                            )
                                                        }
                                                    />
                                                </td>
                                            )}
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        onClick={() =>
                                                            handleRowClick(row)
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                getCellColor(
                                                                    row.original,
                                                                    cell.column.column
                                                                ),
                                                            color: getCellTextColor(
                                                                row.original,
                                                                cell.column.column
                                                            ),
                                                        }}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        {row.isExpanded ? (
                                            <tr>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            onClick={() =>
                                                                handleRowClick(row)
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    getCellColor(
                                                                        row.original,
                                                                        cell.column.column
                                                                    ),
                                                                color: getCellTextColor(
                                                                    row.original,
                                                                    cell.column.column
                                                                ),
                                                            }}
                                                        >
                                                            {cell.render("Cell")}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ) : null}
                                    </React.Fragment>
                                );
                            })
                        )}
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
};

export default GridElement;
